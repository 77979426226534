import logo from "../../images/logo.svg"
import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const ImageBlock = styled.img`
  width: 100%;
  height: ${props => (props.height ? props.height : "auto")};
  margin: ${props => (props.margin ? props.margin : "0")};
  width: ${props => (props.width ? props.width : "100%")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "0")};

  ${props => (props.position ? props.position : "")};

  @media ${device.tablet} {
    width: ${props => (props.widthSm ? props.widthSm : "100%")};
    height: ${props => (props.heightSm ? props.heightSm : "auto")};
    margin: ${props => (props.marginSm ? props.marginSm : "0")};
    ${props => (props.positionSm ? props.positionSm : "static")};
  }

  @media ${device.laptop} {
    width: ${props => (props.widthMd ? props.widthMd : "100%")};
    height: ${props => (props.heightMd ? props.heightMd : "auto")};
    margin: ${props => (props.marginMd ? props.marginMd : "0")};
    ${props => (props.positionMd ? props.positionMd : "static")};
  }

  @media ${device.laptopL} {
    width: ${props => (props.widthLg ? props.widthLg : "100%")};
    height: ${props => (props.heightXl ? props.heightXl : props.heightMd)};
    margin: ${props => (props.marginLg ? props.marginLg : "0")};
    ${props => (props.positionLg ? props.positionLg : "static")};
  }
`

export default ImageBlock
