import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import P from "../components/P"
import H1 from "../components/H1"
import H2 from "../components/H2"
import H3 from "../components/H3"
import H4 from "../components/H4"
import Section from "../components/Section"
import Grid from "../components/Grid"
import OrderedList from "../components/OrderedList"
import Item from "../components/OrderedList/Item"
import privacy from "../images/privacy.svg"
import Hero2 from "../components/Hero2"
import LinkButton from "../components/LinkButton"
import ImageBlock from "../components/ImageBlock"
import List from "../components/List"

const meta = {
  title: "Datenschutzerklärung",
  description: `Wie wir Ihre Daten verarbeiten. Lesen Sie alles in der Datenschutzerklärung.`,
  keywords: "datenschutz, dsvgo, google analytics",
}

const DatenschutzPage = () => {
  return (
    <Layout>
      <SEO
        title={meta.title}
        description={meta.description}
        keywords={meta.keywords}
      />

      <Hero2 height={"100vh"}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <H1>Datenschutzerklärung</H1>
              <P>
                Wie wir mit Ihren Daten umgehen erfahren Sie auf dieser Seite.
              </P>
              <LinkButton to="/datenschutzerklaerung#read">
                Mehr erfahren
              </LinkButton>
            </Grid>
          </Grid>
        </Container>
        <ImageBlock
          alt="Webentwicklung"
          src={privacy}
          width={"640px"}
          widthSm={"960px"}
          widthMd={"800px"}
          widthLg={"50%"}
          position={{
            position: "absolute",
            bottom: "-5%",
            right: "-120px",
            zIndex: "-1",
          }}
          positionMd={{
            position: "absolute",
            bottom: "0%",
            right: "-220px",
            zIndex: "-1",
          }}
          positionLg={{
            position: "absolute",
            bottom: "0",
            right: "-220px",
            zIndex: "-1",
          }}
        />
      </Hero2>

      <Section marginBottom="0" marginBottomSm="0" marginBottomMd="0">
        <Container id="read">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <H2
                margin={"0 0 40px 0"}
                marginSm={"0 0 40px 0"}
                marginMd={"0 0 40px 0"}
                marginLg={"0 0 40px 0"}
              >
                Datenschutzbestimmungen
              </H2>

              <OrderedList>
                <Item>
                  <H3>
                    Grundsätzliche Angaben zur Datenverarbeitung und
                    Rechtsgrundlagen
                  </H3>
                  <P>
                    Diese Datenschutzerklärung klärt Sie über die Art, den
                    Umfang und Zweck der Verarbeitung von personenbezogenen
                    Daten im Zusammenhang mit den Funktionalitäten unserer
                    Webseite und den darauf angebotenen Diensten auf. Sie gilt
                    unabhängig von den verwendeten Domains, Systemen,
                    Plattformen und Geräten (beispielsweise Desktop oder
                    Mobile), auf denen die Website angeschaut wird.
                  </P>
                  <P>
                    Zu den im Rahmen unserer Webseite verarbeiteten
                    personenbezogenen Daten gehören ausschliesslich
                    Nutzungsdaten (z.B., die besuchten Webseiten unseres
                    Onlineangebotes, Interesse an unseren Dienstleistungen). Der
                    Begriff „Nutzer“ umfasst alle Kategorien von der
                    Datenverarbeitung betroffener Personen.
                  </P>
                  <P>
                    Zu den im Rahmen einer Geschäftsbeziehung (im Rahmen einer
                    Anfrage oder eines Auftrags) verarbeiteten personenbezogenen
                    Daten gehören Bestandsdaten (z.B., Namen und Adressen von
                    Kunden), Vertragsdaten (z.B., in Anspruch genommene
                    Leistungen, Namen von Firmen und Personen), Nutzungsdaten
                    (z.B., die besuchten Webseiten unseres Onlineangebotes,
                    Interesse an unseren Dienstleistungen) und Inhaltsdaten
                    (z.B., Eingaben im Kontaktformular oder Kommentare). Zu
                    ihnen gehören unsere Geschäftspartner, Kunden, Interessenten
                    und sonstige Besucher unserer Webseiten und deren
                    Onlineangebote.
                  </P>
                  <P>
                    Wir verarbeiten Ihre personenbezogenen Daten nur unter
                    Einhaltung der entsprechenden Datenschutzbestimmungen. Das
                    bedeutet, Ihre Daten werden nur bei Vorliegen einer
                    gesetzlichen Erlaubnis verarbeitet. D.h., insbesondere wenn
                    die Datenverarbeitung zur Erbringung unserer vertraglichen
                    Leistungen (z.B. Bearbeitung von Aufträgen) sowie
                    Online-Services erforderlich, bzw. gesetzlich vorgeschrieben
                    ist, eine Einwilligung vorliegt, als auch aufgrund unserer
                    berechtigten Interessen (d.h. Interesse an der Analyse,
                    Optimierung und wirtschaftlichem Betrieb und Sicherheit
                    unseres Onlineangebotes, insbesondere bei der
                    Reichweitenmessung, Erstellung von Profilen zu Werbe- und
                    Marketingzwecken sowie Erhebung von Zugriffsdaten und
                    Einsatz der Dienste von Drittanbietern).
                  </P>
                </Item>
                <Item>
                  <H3>Sicherheitsmassnahmen</H3>
                  <P>
                    Die Website codeflow.ch wird auf Servern des
                    Hostingproviders netlify.com betrieben. Die Verbindung zu
                    den Servern erfolgt mittels 256-Bit SSL-Verschlüsselung (zu
                    erkennen an der URL, die mit «https» beginnt und der grünen
                    Markierung in der Adresszeile ihres Browsers). Unsere
                    Mitarbeiter und die von uns beauftragten
                    Dienstleistungsunternehmen sind von uns zur Verschwiegenheit
                    und zur Einhaltung der datenschutzrechtlichen Bestimmungen
                    verpflichtet worden.
                  </P>
                </Item>
                <Item>
                  <H3>Weitergabe von Daten an Dritte und Drittanbieter</H3>
                  <P>
                    Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie
                    ausdrücklich eingewilligt haben, hierfür eine gesetzliche
                    Verpflichtung besteht oder dies zur Durchsetzung unserer
                    Rechte, insbesondere zur Durchsetzung von Ansprüchen aus
                    einem Vertragsverhältnis erforderlich ist. Wir können Ihre
                    personenbezogenen Daten auch weitergeben, wenn dies auf
                    Grundlage berechtigter Interessen an wirtschaftlichem und
                    effektivem Betrieb unseres Geschäftsbetriebes beruht.
                  </P>
                  <P>
                    Darüber hinaus geben wir Ihre Daten an aussenstehende
                    Dienstleister weiter, soweit dies zur Vertragsabwicklung
                    erforderlich ist. Diese Dienstleister nutzen Ihre Daten
                    ausschliesslich für die Auftragsabwicklung und nicht für
                    weitere Zwecke. Wir werden Ihre persönlichen Daten niemals
                    an Dritte verkaufen.
                  </P>
                  <P>
                    Sofern im Rahmen dieser Datenschutzerklärung Inhalte,
                    Werkzeuge oder sonstige Mittel von anderen Anbietern
                    (nachfolgend gemeinsam bezeichnet als „Drittanbieter“)
                    eingesetzt werden und deren genannter Sitz sich in einem
                    Drittland (also nicht in der Schweiz) befindet, ist davon
                    auszugehen, dass ein Datentransfer in die Sitzstaaten der
                    Drittanbieter stattfindet. Die Übermittlung von Daten in
                    Drittstaaten erfolgt entweder, wenn ein angemessenes
                    Datenschutzniveau, eine Einwilligung der Nutzer oder sonst
                    eine gesetzliche Erlaubnis vorliegt.
                  </P>
                  <P>
                    Für gewisse Dienste arbeiten wir mit spezialisierten
                    Unternehmen zusammen (beispielsweise für den Betrieb unserer
                    E-Mail Infrastruktur und Newsletter). Dafür werden
                    unsererseits personenbezogene Daten transferiert, um eben
                    diesen Dienst betreiben zu können. Hier ergreifen wir
                    geeignete rechtliche Vorkehrungen sowie entsprechende
                    technische und organisatorische Massnahmen, um für den
                    Schutz der personenbezogenen Daten gemäss den einschlägigen
                    gesetzlichen Vorschriften zu sorgen.
                  </P>
                </Item>
                <Item>
                  <H3>Erfassen von Nutzungsdaten</H3>
                  <P>
                    Ihre persönlichen Daten werden während der Nutzung unserer
                    Webseiten in folgendem Umfang und zu folgenden Zwecken von
                    uns erhoben und verwendet:
                  </P>
                  <H4>Logfile-Informationen bei Besuch unserer Webseiten</H4>
                  <P>
                    Beim Besuch unserer Website speichern unsere Server temporär
                    jeden Zugriff in einer Protokolldatei. Folgende technischen
                    Daten werden dabei ohne Ihr Zutun erfasst und bis zur
                    automatisierten Löschung nach spätestens 190 Tagen von uns
                    gespeichert:
                  </P>
                  <ul>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        die IP-Adresse des anfragenden Rechners
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        der Name des Inhabers des IP-Adressbereichs (i.d.R. Ihr
                        Internet-Access-Provider)
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        das Datum und die Uhrzeit des Zugriffs
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        die Website, von der aus der Zugriff erfolgte (Referrer
                        URL), ggf. mit verwendetem Suchwort
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        der Name und die URL der abgerufenen Datei
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        den Status-Code (z.B. Server-/Client-Fehler)
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        das Betriebssystem Ihres Rechners
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        der von Ihnen verwendete Browser (Typ, Version und
                        Sprache)
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        das verwendete Übertragungsprotokoll (z.B. HTTP/1.1)
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0 0 40px 0"}
                        marginSm={"0 0 40px 0"}
                        marginMd={"0 0 40px 0"}
                        marginLg={"0 0 40px 0"}
                      >
                        Die Erhebung und Verarbeitung dieser Daten erfolgt zu
                        dem Zweck, die Nutzung unserer Website zu ermöglichen
                        (Verbindungsaufbau), die Systemsicherheit und
                        -stabilität dauerhaft zu gewährleisten und die
                        Optimierung unseres Internetangebots zu ermöglichen
                        sowie zu internen statistischen Zwecken.
                      </P>
                    </li>
                  </ul>
                  <P>
                    Darüber werden die Daten zur Aufklärung und Abwehr von
                    Angriffen auf die Netzinfrastruktur oder anderen unerlaubten
                    oder missbräuchlichen Website-Nutzungen ausgewertet und
                    gegebenenfalls im Rahmen eines Strafverfahrens zur
                    Identifikation und zum zivil- und strafrechtlichen Vorgehen
                    gegen die betreffenden Nutzer verwendet.
                  </P>

                  <P>
                    Logfile-Informationen werden aus Sicherheitsgründen (z.B.
                    zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für
                    die Dauer von maximal 190 Tagen gespeichert und danach
                    gelöscht. Daten, deren weitere Aufbewahrung zu Beweiszwecken
                    erforderlich ist, sind bis zur endgültigen Klärung des
                    jeweiligen Vorfalls von der Löschung ausgenommen.
                  </P>

                  <P>
                    Schliesslich setzen wir beim Besuch unserer Website Cookies
                    sowie Anwendungen ein, welche auf dem Einsatz von Cookies
                    basieren.
                  </P>
                </Item>
                <Item>
                  <H3>Cookies</H3>
                  <P>
                    Cookies sind kleine Textdateien, die auf Ihrem Computer
                    gespeichert sind. Cookies helfen unter vielen Aspekten,
                    Ihren Besuch auf unserer Webseite einfacher, angenehmer und
                    sinnvoller zu gestalten. Cookies sind Informationsdateien,
                    die Ihr Webbrowser automatisch auf der Festplatte Ihres
                    Computers speichert, wenn Sie unsere Internetseite besuchen.
                  </P>
                  <P>
                    Cookies beschädigen weder die Festplatte Ihres Rechners noch
                    können sie andere auf Ihrer Festplatte gespeicherte Daten
                    aufnehmen.
                  </P>
                  <P>
                    Die meisten Internet-Browser akzeptieren Cookies
                    automatisch. Sie können Ihren Browser jedoch so
                    konfigurieren, dass keine Cookies auf Ihrem Computer
                    gespeichert werden oder stets ein Hinweis erscheint, wenn
                    Sie ein neues Cookie erhalten. Auf den nachfolgenden Seiten
                    finden Sie Erläuterungen, wie Sie die Verarbeitung von
                    Cookies bei den gängigsten Browsern konfigurieren können:
                  </P>

                  <ul>
                    <li>
                      <a href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies">
                        Microsofts Windows Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://support.microsoft.com/en-us/help/10671/windows-phone-tips-and-tricks">
                        Microsofts Windows Internet Explorer Mobile
                      </a>
                    </li>

                    <li>
                      <a href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">
                        Mozilla Firefox
                      </a>
                    </li>
                    <li>
                      <a href="https://support.google.com/chrome/answer/95647?hl=de">
                        Google Chrome für Desktop
                      </a>
                    </li>

                    <li>
                      <a href="https://support.google.com/chrome/answer/95647?hl=de">
                        Google Chrome für Mobile
                      </a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/kb/PH17191?locale=en_US&viewlocale=de_DE">
                        Apple Safari für Desktop
                      </a>
                    </li>
                    <li>
                      <a href="https://support.apple.com/de-de/HT201265">
                        Apple Safari für Mobile
                      </a>
                    </li>
                  </ul>
                  <br />
                  <P>
                    Sie können dem Einsatz von Cookies, die der
                    Reichweitenmessung und Werbezwecken dienen, auch über die
                    Deaktivierungsseite der Netzwerkwerbeinitiative
                    networkadvertising.org und zusätzlich die US-amerikanische
                    Webseite aboutads.info oder die europäische Webseite
                    <a href="youronlinechoices.com">
                      {" "}
                      youronlinechoices.com{" "}
                    </a>{" "}
                    widersprechen.
                  </P>
                  <P>
                    Die Deaktivierung von Cookies kann dazu führen, dass Sie
                    nicht alle Funktionen unserer Webseiten nutzen können.
                  </P>
                </Item>
                <Item>
                  <H3>Google Analytics & Google Adwords</H3>

                  <P>
                    Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden
                    Optimierung unserer Seiten nutzen wir den Webanalyse-Dienst
                    Google Analytics. In diesem Zusammenhang werden
                    pseudonymisierte Nutzungsprofile erstellt und kleine
                    Textdateien, die auf Ihrem Computer gespeichert sind
                    („Cookies“), verwendet. Zusätzlich zu den vorgänging
                    genannten Daten erhalten wir dadurch unter Umständen
                    folgende Informationen:
                  </P>
                  <ul>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        {" "}
                        Navigationspfad, den ein Besucher auf der Site
                        beschreitet
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        Verweildauer auf der Website oder Unterseite
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        die Unterseite, auf welcher die Website verlassen wird
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        das Land, die Region oder die Stadt, von wo ein Zugriff
                        erfolgt
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0"}
                        marginSm={"0"}
                        marginMd={"0"}
                        marginLg={"0"}
                      >
                        Endgerät (Typ, Version, Farbtiefe, Auflösung, Breite und
                        Höhe des Browserfensters) und
                      </P>
                    </li>
                    <li>
                      <P
                        margin={"0 0 40px 0"}
                        marginSm={"0 0 40px 0"}
                        marginMd={"0 0 40px 0"}
                        marginLg={"0 0 40px 0"}
                      >
                        Wiederkehrender oder neuer Besucher
                      </P>
                    </li>
                  </ul>
                  <P>
                    Diese Daten werden an Server von Google Inc. in die USA
                    übertragen und dort gespeichert. Die Informationen werden
                    verwendet, um die Nutzung der Website auszuwerten, um
                    Reports über die Websiteaktivitäten zusammenzustellen und um
                    weitere mit der Websitenutzung und der Internetnutzung
                    verbundene Dienstleistungen zu Zwecken der Marktforschung
                    und bedarfsgerechten Gestaltung dieser Internetseiten zu
                    erbringen. Auch werden diese Informationen gegebenenfalls an
                    Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist
                    oder soweit Dritte diese Daten im Auftrag verarbeiten. Es
                    wird in keinem Fall die IP-Adresse mit anderen den Nutzer
                    betreffenden Daten in Verbindung gebracht werden. Die
                    IP-Adressen werden anonymisiert, so dass eine Zuordnung
                    nicht möglich ist (IP-Masking).
                  </P>
                  <P>
                    Der Nutzer kann die Installation der Cookies durch eine
                    entsprechende Einstellung der Browser-Software verhindern;
                    wir weisen jedoch darauf hin, dass in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser Website
                    vollumfänglich genutzt werden können. Weitere Informationen
                    über den genutzten Webanalyse-Dienst finden Sie auf der
                    Website von Google Analytics. Eine Anleitung, wie sie die
                    Verarbeitung Ihrer Daten durch den Webanalyse-Dienst
                    verhindern können, finden Sie unter{" "}
                    <a href="tools.google.com">tools.google.com</a>
                  </P>
                  <P>
                    Beim AdWords Conversion Tracking wird zur
                    Leistungsbestimmung ebenfalls ein Cookie verwendet, welches
                    bis zu 30 Tage auf Ihrem Computer gespeichert wird, nachdem
                    Sie auf eine AdWords-Anzeige geklickt haben. Die
                    Informationen beschränken sich auf die Gesamtzahl der
                    Nutzer, die zuerst auf eine Anzeige geklickt haben und dann
                    zu einer mit einem Conversion-Tracking-Tag versehenen Seite
                    weitergeleitet wurden. Sie erhalten jedoch keine
                    Informationen zur persönlichen Identifizierung der Nutzer.
                    Sie können die Installation der Cookies durch eine
                    entsprechende Einstellung in Ihrem Browser verhindern; wir
                    weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                    gegebenenfalls nicht sämtliche Funktionen dieser Website
                    voll umfänglich nutzen können.
                  </P>
                  <P>
                    Durch die Nutzung dieser Website erklären Sie sich mit der
                    Bearbeitung der über Sie erhobenen Daten durch Google in der
                    zuvor beschriebenen Art und Weise und zu dem zuvor benannten
                    Zweck einverstanden.
                  </P>
                </Item>
                <Item>
                  <H3>Anspruch auf Auskunft, Löschung und Berichtigung</H3>
                  <P>
                    Sie können jederzeit Auskunft über Ihre von uns
                    gespeicherten persönlichen Daten verlangen. Ein formelles
                    Auskunftsersuchen hat schriftlich und unter Nachweis der
                    Identität zu erfolgen.
                  </P>
                  <P>
                    Wir speichern persönliche Daten, die Sie uns übermitteln, so
                    lange, wie wir diese für nützlich erachten, um Sie in Bezug
                    auf den Newsletterdienst oder unsere anderen Dienste zu
                    kontaktieren, oder um unsere gesetzlichen Pflichten zu
                    erfüllen, Streitigkeiten beizulegen und unsere Verträge
                    durchzusetzen. Danach löschen wir die Daten in sicherer
                    Weise. Sie können Ihre Daten auch jederzeit löschen oder
                    berichtigen lassen. Dies kann durch Versand einer
                    entsprechenden Bitte per E-Mail an{" "}
                    <a href="mail@klarplan.ch">info@codeflow.ch</a> geschehen.
                    Wir weisen Sie darauf hin, dass im Falle der Löschung Ihrer
                    Daten eine Inanspruchnahme unserer Dienste nicht oder nicht
                    in vollem Umfang möglich ist.
                  </P>
                  <P>
                    Einwilligungen in bestimmte Datenverarbeitungen können Sie
                    jederzeit mit Wirkung für die Zukunft widerrufen.
                  </P>
                  <P>
                    Bitte beachten Sie, dass für bestimmte Daten gesetzlich
                    Aufbewahrungsfristen gelten. Diese Daten müssen bis zum
                    Ablauf der Frist von uns gespeichert werden. Wir sperren
                    diese Dateien in unserem System und nutzen Sie
                    ausschliesslich zur Erfüllung der gesetzlichen Pflichten.
                  </P>
                </Item>
                <Item>
                  <H3>Ansprechpartner</H3>
                  <P>
                    Wenn Sie Fragen zum Datenschutz auf unserer Webseite haben,
                    Auskünfte verlangen oder die Löschung Ihrer Daten beantragen
                    möchten, wenden Sie sich bitte an unseren Ansprechpartner
                    für Datenschutzrecht, in dem Sie eine E-Mail an{" "}
                    <a href="info@codeflow.ch">info@codeflow.ch</a> schicken.
                  </P>

                  <P>Stand 27. Juli 2020</P>
                </Item>
              </OrderedList>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </Layout>
  )
}

export default DatenschutzPage
