import styled from "styled-components"

const OrderedList = styled.ol`
  list-style-type: none;
  counter-reset: listNumbering;
  padding: 0;
  margin: 0;
`

export default OrderedList
