import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const H1 = styled.h1`
  font-size: ${theme.main.sizes.typography.mobile.h1};
  margin: 0 0 8px 0;
  color: ${props => (props.color ? props.color : theme.main.colors.black)};

  @media ${device.tablet} {
    font-size: ${theme.main.sizes.typography.tablet.h1};
    margin: ${props => (props.marginSm ? props.marginSm : "margin: 0 0 8px 0")};
  }

  @media ${device.laptop} {
    font-size: ${theme.main.sizes.typography.desktop.h1};
    margin: ${props => (props.marginMd ? props.marginMd : props.marginSm)};
  }

  @media ${device.laptopL} {
    font-size: ${theme.main.sizes.typography.desktop.h1};
    margin: ${props => (props.marginLg ? props.marginLg : props.marginMd)};
  }
`

export default H1
