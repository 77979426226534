import styled from "styled-components"
import { device } from "../../themes/device"

const Section = styled.section`
  position: relative;
  padding-top: ${props => (props.hero ? "32px;" : 0)};
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : "240px"};
  overflow: hidden;
  box-sizing: border-box;

  @media ${device.tablet} {
    padding: ${props => (props.paddingSm ? props.paddingSm : "0")};
    margin: ${props => (props.marginSm ? props.marginSm : props.margin)};
  }

  @media ${device.laptop} {
    margin-bottom: ${props =>
      props.marginBottomMd ? props.marginBottomMd : "600px"};
    padding: ${props => (props.paddingMd ? props.paddingMd : "0")};

    background: ${props =>
      props.backgroundMd ? `url(${props.backgroundMd}) no-repeat` : "none"};
    background-size: 80%;
    background-position: bottom right;
  }

  @media ${device.laptopL} {
    padding: ${props => (props.paddingLg ? props.paddingLg : "0")};
    margin: ${props => (props.marginLg ? props.marginLg : props.marginMd)};
  }
`

export default Section
