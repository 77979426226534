import styled from "styled-components"
import { device } from "../../themes/device"
import theme from "../../themes"

const Item = styled.li`
  counter-increment: listNumbering;
  font-size: ${theme.main.sizes.typography.mobile.h2};
  line-height: 36px;
  margin: 0 0 8px 0;
  color: ${props => (props.color ? props.color : theme.main.colors.black)};

  &:before {
    content: counter(listNumbering, decimal-leading-zero) ".";
    color: gray;
    @media ${device.laptop} {
      font-size: ${theme.main.sizes.typography.tablet.h2};
      line-height: 38px;
      margin: ${props => (props.marginMd ? props.marginMd : props.marginSm)};
    }

    @media ${device.laptopL} {
      font-size: ${theme.main.sizes.typography.desktop.h2};
      line-height: 48px;
      margin: ${props => (props.marginLg ? props.marginLg : props.marginMd)};
    }
  }
`

export default Item
