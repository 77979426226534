import styled from "styled-components"
import { default as MaterialGrid } from "@material-ui/core/Grid"
import { device } from "../../themes/device"

const Grid = styled(MaterialGrid)`
  order: ${props => (props.order ? props.order : "0")};

  @media ${device.tablet} {
    order: ${props => (props.orderSm ? props.orderSm : "0")};
  }

  @media ${device.laptop} {
    order: ${props => (props.orderMd ? props.orderMd : "0")};
  }
`
export default Grid
