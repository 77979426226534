import styled from "styled-components"
import theme from "../../themes"
import { device } from "../../themes/device"

const Hero2 = styled.section`
  position: relative;
  overflow: hidden;
  color: ${theme.main.colors.black};
  height: ${props => (props.height ? props.height : "90vh")};
  margin-bottom: ${props =>
    props.marginBottom ? props.marginBottom : "240px"};
  background: ${props => (props.background ? props.background : "none")};
  //padding: ${props => (props.padding ? props.padding : "40px 16px")};
  box-sizing: border-box;
  padding: 40px 0;
  display: flex;

  @media ${device.tablet} {
    //padding: ${props => (props.padding ? props.padding : "40px")};
    margin-bottom: ${props =>
      props.marginBottomSm ? props.marginBottomSm : "240px"};
  }

  @media ${device.laptop} {
    padding: 160px 0;
    margin-bottom: ${props =>
      props.marginBottomMd ? props.marginBottomMd : "240px"};
  }

`

export default Hero2
