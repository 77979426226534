import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { device } from "../../themes/device"
import theme from "../../themes"

const LinkButton = styled(AnchorLink)`
  border: 2px solid black;
  border-radius: 140px;
  background: white;
  padding: 8px 16px;
  text-decoration: none;
  text-align: center;
  outline: none;
  color: black;
  display: inline-block;
  z-index: 10;
  font-size: 22px;

  @media ${device.laptopL} {
    font-size: ${theme.main.sizes.typography.desktop.p};
    line-height: 34px;
    margin: 0 0 40px 0;
  }
`

export default LinkButton
